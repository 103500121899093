<script>
import html2pdf from "html2pdf.js"
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import Preloader from "@/components/preloader";
import PageHeader from "@/components/page-header";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/pl";
import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";
import { typeOfApplications } from "@/data/data-type-of-application-status";
import Repository from "@/app/repository/repository-factory";
import { roleService } from "@/app/service/roleService";

const PaymentReportRepository = Repository.get("PaymentReportRepository");
const PaymentCompanyRepository = Repository.get("PaymentCompanyRepository");

export default {
  page: {
    title: "Raporty - Potwierdzenia przelewów",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    Preloader,
    DatePicker,
    Multiselect,
  },
  data() {
    return {
      preloader: false,
      raportData: null,
      raportDataByCompany: null,
      raportDataByYearMonth: null,
      form: {
        reportDateRange: null,
        companies: [],
        typeOfApplication: [],
      },
      raportFields: {
        Spółka: "companyName",
        Wniosek: "typeOfApplication",
        Rok: "reportDateYear",
        Miesiąc: "reportDateMonth",
        Ilość: "countConfirmations",
      },
      companySelectOptions: [],
      typeOfApplicationSelectOptions: typeOfApplications,
      header: {
        title: "Potwierdzenia przelewów",
        items: [
          {
            text: "Strona główna",
            to: { name: "home" },
          },
          {
            text: "Raporty",
          },
          {
            text: "Potwierdzenia przelewów",
          },
        ],
      },
    };
  },
  validations: {
    form: {
      reportDateRange: {
        required,
      },
    },
  },
  created() {
    this.getCompanies();
  },
  methods: {
    async getRealisedApps(params) {
      try {
        const {
          data,
        } = await PaymentReportRepository.getAttachedTransferConfirmations(
          params
        );
        this.raportData = data;
        const groupBy = (key) => (array) =>
          array.reduce((objectsByKeyValue, obj) => {
            obj.reportDateYearMonth =
              obj.reportDateYear + "-" + obj.reportDateMonth;
            const value = obj[key];
            objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(
              obj
            );
            return objectsByKeyValue;
          }, {});

        const groupByCompany = groupBy("companyName");
        const groupByYearMonth = groupBy("reportDateYearMonth");

        this.raportDataByCompany = groupByCompany(data);
        this.raportDataByYearMonth = groupByYearMonth(data);
        var tempRaportObj = {};

        Object.entries(this.raportDataByCompany).forEach(([key, val]) => {
          var output = [];

          val.forEach(function (item) {
            var existing = output.filter(function (v, i) {
              return (
                v.companyName == item.companyName &&
                v.typeOfApplication == item.typeOfApplication
              );
            });
            if (existing.length) {
              var existingIndex = output.indexOf(existing[0]);
              output[existingIndex][item.reportDateYearMonth] = {
                countConfirmations: item.countConfirmations,
              };
            } else {
              item[item.reportDateYearMonth] = {
                countConfirmations: item.countConfirmations,
              };
              output.push(item);
            }
          });

          tempRaportObj[key] = output;
        });

        this.raportDataByCompany = tempRaportObj;
      } catch (error) {
        console.log(error);
      }
    },
    async getData(params) {
      this.preloader = true;
      try {
        await Promise.all([this.getRealisedApps(params)]);
        this.preloader = false;
      } catch (error) {
        this.preloader = false;
        console.log(error);
      }
    },
    getCompanies() {
      PaymentCompanyRepository.getAll()
        .then((response) => {
          let companies = [];
          let i;

          for (i = 0; i < response.data.length; i++) {
            companies.push({
              text: response.data[i].name,
              value: response.data[i].businessUnitCode,
            });
          }

          this.companySelectOptions = companies;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    clearAllCompanies() {
      this.form.companies = [];
    },
    clearAllTypeOfApplication() {
      this.form.typeOfApplication = [];
    },
    clearAllTypeOfTransfer() {
      this.form.typeOfApplication = [];
    },
    regenerateReport() {
      if (
        this.form.reportDateRange[0] != null &&
        this.form.reportDateRange[1] != null
      ) {
        let params =
          "?startDate=" +
          this.form.reportDateRange[0] +
          "&endDate=" +
          this.form.reportDateRange[1] +
          "&filterCompany=" +
          this.form.companies.map((e) => e.value).join(",") +
          "&filterTypeOfApplication=" +
          this.form.typeOfApplication.map((e) => e.value).join(",");
        this.getData(params);
      }
    },
    exportToPdf () {
      html2pdf(this.$refs.domElementToPDF, {
        margin: 0.5,
        filename: 'reportTableExport.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        // html2canvas: { dpi: 192, letterRendering: true },
        html2canvas:  { scale: 2 },
        jsPDF: { unit: 'in', format: 'A4', orientation: 'landscape'},
      })
    },
  },
  computed: {
    isAdmin() {
      return roleService.isAdmin();
    },
  },
};
</script>

<template>
  <Layout>
    <Preloader v-if="preloader" />
    <PageHeader :title="header.title" :items="header.items" />
    <div class="row" v-if="isAdmin">
      <div class="col-12">
        <b-card class="shadow-sm">
          <div class="font-weight-bold" slot="header">Ustawienia parametrów raportu</div>
          <div class="row">
            <div class="col-md-12 col-lg-4">
              <label for="reportDateRange">
                Zakres dat
                <em class="text-danger">*</em>
              </label>
              <div class="form-group">
                <date-picker id="reportDateRange" v-model="form.reportDateRange" value-type="format" format="YYYY-MM-DD" :editable="false" :first-day-of-week="1" @input="$v.form.reportDateRange.$touch()" :class="{ 'is-invalid': $v.form.reportDateRange.$error, 'is-valid': !$v.form.reportDateRange.$invalid && !$v.form.reportDateRange.$error }" lang="pl" placeholder="YYYY-MM-DD ~ YYYY-MM-DD" range required />
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="form-group">
                <label class="typo__label">Wybierz spółkę</label>
                <multiselect v-model="form.companies" :multiple="true" :options="companySelectOptions" :preserve-search="true" track-by="value" label="text" placeholder="Wybierz spółkę" select-label="" deselect-label="Odznacz ✕" selected-label="Wybrane ✓">
                  <template slot="selection" slot-scope="{ values, search, isOpen }">
                    <span v-if="values.length && !isOpen" :data-search="search" class="multiselect__single">Wybranych spółek: {{ values.length }}</span>
                  </template>
                  <template slot="clear" slot-scope="props">
                    <div class="multiselect__clear" v-if="form.companies.length" @mousedown.prevent.stop="clearAllCompanies(props.search)"></div>
                  </template>
                  <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
                </multiselect>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="form-group">
                <label class="typo__label">Rodzaj wniosku</label>
                <multiselect v-model="form.typeOfApplication" :multiple="true" :options="typeOfApplicationSelectOptions" :preserve-search="true" track-by="value" label="text" placeholder="Wybierz rodzaj wniosku" select-label="" deselect-label="Odznacz ✕" selected-label="Wybrane ✓">
                  <template slot="selection" slot-scope="{ values, search, isOpen }">
                    <span v-if="values.length && !isOpen" :data-search="search" class="multiselect__single">Wybranych wniosków: {{ values.length }}</span>
                  </template>
                  <template slot="clear" slot-scope="props">
                    <div class="multiselect__clear" v-if="form.typeOfApplication.length" @mousedown.prevent.stop="clearAllTypeOfApplication(props.search)"></div>
                  </template>
                  <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
                </multiselect>
              </div>
            </div>
            <div class="col-md-6 text-left mb-3">
              <download-excel class="btn btn-success" :stringifyLongNum="true" v-if="raportData && isAdmin" :data="raportData" :fields="raportFields" worksheet="My Worksheet" name="filename.xls">
                <i class="mdi mdi-file-excel mr-2"></i>Pobierz Excel
              </download-excel>
              <button class="btn btn-info ml-2" v-if="raportData && isAdmin" @click="exportToPdf">Zrzut tabeli do PDF</button>
            </div>
            <div class="col-md-6 text-right mb-3">
              <button v-if="isAdmin" class="btn btn-dark" @click="regenerateReport" :disabled="$v.form.$invalid" type="submit"><i class="mdi mdi-cogs mr-2"></i>Generuj raport</button>
            </div>
          </div>
        </b-card>
      </div>
      <div class="col-12" v-if="raportData">
        <div class="card shadow-sm">
          <div class="table-responsive mb-0">
            <table class="table table-sm border-bottom" ref="domElementToPDF">
              <thead>
                <tr class="bg-soft-info" v-if="raportData.length > 0">
                  <th colspan="3" class="text-dark pl-2">Raport ilości załączonych plików będących potwierdzeniem przelewu w podziale na spółki i miesiące</th>
                  <template v-for="(yearmonth, index, key) in raportDataByYearMonth">
                    <th :key="index" class="text-center border" :class="{'bg-soft-info': key % 2 === 0 }">{{ index }}</th>
                  </template>
                </tr>
                <tr class="bg-dark text-white">
                  <th class="text-center">#</th>
                  <th>Spółka</th>
                  <th>Rodzaj wniosku</th>
                  <template v-for="(m, index, key) in raportDataByYearMonth">
                    <th class="text-center" :class="{'bg-dark': key % 2 === 0 }" :key="index">Ilość</th>
                  </template>
                </tr>
              </thead>
              <tbody>
                <template v-for="(company, index2, key2) in raportDataByCompany">
                  <tr v-for="(item, index, key) in company" :key="key" :class="{'bg-soft-light': key2 % 2 === 0 }">
                    <td v-if="index == 0" :rowspan="company.length" class="text-center">{{ key2 + 1 }}</td>
                    <td v-if="index == 0" :rowspan="company.length"><strong>{{ item.companyName }}</strong></td>
                    <td>{{ item.typeOfApplication }}</td>
                    <template v-for="(yearmonth, index2, key) in raportDataByYearMonth">
                      <td :key="index2" class="text-center border-left" :class="{'bg-white': key % 2 === 0 }">
                        <span v-if="item[index2]">
                          {{ item[index2].countConfirmations }}
                        </span>
                      </td>
                    </template>
                  </tr>
                </template>
              </tbody>
            </table>
            <div class="card-body">
              <template v-if="!raportData || raportData.length == 0">
                <div class="d-flex justify-content-center">
                  <b>Nie znaleziono elementów</b>
                </div>
              </template>
              <template v-else>
                <div class="d-flex justify-content-between">
                  Znaleziono {{ raportData.length | rekord }} w zakresie dat {{ form.reportDateRange[0] + ' ~ ' + form.reportDateRange[1]}}
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
